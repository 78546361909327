var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardWrapper',{scopedSlots:_vm._u([(!_vm.isSpecial && _vm.course && !_vm.readonly)?{key:"hintTop",fn:function(){return [_c('CardHint',{attrs:{"text":"При изменении информации в данной категории, она меняется во всех созданных группах курса"}})]},proxy:true}:null,(!_vm.readonly)?{key:"footer",fn:function(){return [_c('VBtn',{attrs:{"color":"secondary"},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.course ? 'Сохранить' : 'Создать'))])]},proxy:true}:null],null,true)},[_c('ValidationObserver',{ref:"form",staticClass:"course-form",attrs:{"tag":"div"}},[(!_vm.course)?[_c('VCardText',{staticClass:"course-form__grid"},[_c('ValidationProvider',{staticClass:"course-form__cell-3",attrs:{"name":"subject","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"name":"subjectId","label":"Предмет","placeholder":"Выберете предмет","options":_vm.subjects,"dense":"","outlined":"","rounded":"","invalid":errors.length > 0,"error":errors[0]},on:{"input":_vm.handleChoiceSubject},model:{value:(_vm.form.subjectId),callback:function ($$v) {_vm.$set(_vm.form, "subjectId", $$v)},expression:"form.subjectId"}})]}}],null,false,1121855545)}),_c('ValidationProvider',{staticClass:"course-form__cell-3",attrs:{"name":"type","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"name":"type","label":"Тип курса","placeholder":"Выберете тип курса","options":_vm.types,"dense":"","outlined":"","rounded":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})]}}],null,false,631464093)})],1),_c('VDivider',{staticClass:"my-0"})]:_vm._e(),_c('VCardText',{staticClass:"course-form__grid"},[_c('ValidationProvider',{staticClass:"course-form__cell-full",attrs:{"name":"title","rules":"required|max:100","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"title","label":"Название","counter":"100","placeholder":"Введите название курса","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])}),_c('ValidationProvider',{staticClass:"course-form__cell-full space-b-8",attrs:{"name":"annotation","rules":"required|max:115","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextAreaInput',{attrs:{"name":"annotation","persistent-hint":"","hint":"Текст, отображаемый на карточке курса","label":"Аннотация","counter":"115","placeholder":"Введите аннотацию","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.annotation),callback:function ($$v) {_vm.$set(_vm.form, "annotation", $$v)},expression:"form.annotation"}})]}}])}),_c('div',{staticClass:"course-form__cell-full"},[_c('div',{staticClass:"text-body-3"},[_vm._v("Даты проведения")])]),_c('ValidationProvider',{staticClass:"course-form__cell-2",attrs:{"name":"educationStartAt","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"name":"educationStartAt","label":"Начало курса","placeholder":"Выберите дату","min":_vm.form.openDashboardAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.educationStartAt),callback:function ($$v) {_vm.$set(_vm.form, "educationStartAt", $$v)},expression:"form.educationStartAt"}})]}}])}),_c('ValidationProvider',{staticClass:"course-form__cell-2",attrs:{"name":"educationEndAt","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"name":"educationEndAt","label":"Конец курса","placeholder":"Выберите дату","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.educationEndAt),callback:function ($$v) {_vm.$set(_vm.form, "educationEndAt", $$v)},expression:"form.educationEndAt"}})]}}])}),_c('ValidationProvider',{staticClass:"course-form__cell-2",attrs:{"name":"salesEndAt","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"name":"salesEndAt","label":"Конец продаж","placeholder":"Выберите дату","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.salesEndAt),callback:function ($$v) {_vm.$set(_vm.form, "salesEndAt", $$v)},expression:"form.salesEndAt"}})]}}])}),_c('ValidationProvider',{staticClass:"course-form__cell-2",attrs:{"name":"hiddenAt","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{attrs:{"name":"hiddenAt","label":"Скрытие курса","placeholder":"Выберите дату","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.hiddenAt),callback:function ($$v) {_vm.$set(_vm.form, "hiddenAt", $$v)},expression:"form.hiddenAt"}})]}}])}),_c('ValidationProvider',{staticClass:"course-form__cell--dashboard",attrs:{"name":"hiddenAt","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DateInput',{staticClass:"field--dashboard",attrs:{"name":"openDashboardAt","label":"Дата открытия дашборда","placeholder":"Выберите дату","max":_vm.form.educationStartAt,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.openDashboardAt),callback:function ($$v) {_vm.$set(_vm.form, "openDashboardAt", $$v)},expression:"form.openDashboardAt"}}),_c('p',{staticClass:"course-form__dashboard-info"},[_vm._v("Дата показа мастерам данных этого курса на главном дашборде.")])]}}])})],1),_c('VDivider',{staticClass:"my-0"}),_c('VCardText',{staticClass:"course-form__grid"},[_c('ValidationProvider',{staticClass:"course-form__cell-full field",attrs:{"name":"teacher","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VAutocomplete',{attrs:{"items":_vm.teachersList,"item-text":function (item) { return ((item.name) + " " + (item.surname)); },"item-value":"id","label":"Преподаватель","placeholder":"Выберите преподавателя","append-icon":"$arrowDown","multiple":true,"hide-no-data":"","hide-selected":"","outlined":"","error":errors.length > 0,"error-messages":errors[0]},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('VChip',{staticClass:"user-avatar-chip",attrs:{"color":"orange desaturate-1","close":_vm.form.teacherIds.length > 1},on:{"click:close":function($event){return _vm.handleDeleteTeacher(data.item.id)}}},[_c('div',{staticClass:"user-avatar-chip__img"},[_c('img',{attrs:{"src":data.item.photo}})]),_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.surname))])])]}},{key:"item",fn:function(data){return [_c('div',{staticClass:"user-avatar-chip__img"},[_c('img',{attrs:{"src":data.item.photo}})]),_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(data.item.surname))])]}}],null,true),model:{value:(_vm.form.teacherIds),callback:function ($$v) {_vm.$set(_vm.form, "teacherIds", $$v)},expression:"form.teacherIds"}})]}}])}),_c('ValidationProvider',{staticClass:"course-form__cell-full",attrs:{"name":"shopLabel","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"shopLabel","label":"Заголовок при покупке","placeholder":"Введите заголовок при покупке","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.shopLabel),callback:function ($$v) {_vm.$set(_vm.form, "shopLabel", $$v)},expression:"form.shopLabel"}})]}}])})],1),_c('VDivider',{staticClass:"my-0"}),_c('VCardText',{staticClass:"course-form__grid"},[_c('div',{staticClass:"course-form__cell-full course-form__bonus"},[_c('div',{staticClass:"text-body-3"},[_vm._v("Бонусная система или игрофикация")]),_c('div',{staticClass:"course-form__bonus__switch"},[_c('SwitchInput',{attrs:{"disabled":_vm.isSwitcherDisable,"label":"Отключить игрофикацию"},model:{value:(_vm.form.hasGamification),callback:function ($$v) {_vm.$set(_vm.form, "hasGamification", $$v)},expression:"form.hasGamification"}})],1)]),(_vm.form.hasGamification)?_c('div',{staticClass:"course-form__cell-full"},[_c('CardHint',{attrs:{"color":"orange darken-3","rounded":true,"text":"При включении игрофикации для мастеров, отключить её повторно будет невозможно."}})],1):_vm._e(),_c('div',{staticClass:"course-form__cell-full"},[_c('div',{staticClass:"text-body-2"},[_vm._v("Плановое количество занятий в курсе")])]),_c('ValidationProvider',{staticClass:"course-form__cell-2",attrs:{"name":"maxLessons","rules":{ required: !_vm.form.hasGamification, min_value: _vm.form.hasGamification ? 0 : _vm.isSpecial ? 1 : 36 },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"maxLessons","label":"Уроки","type":"number","placeholder":"Уроки","disabled":_vm.form.hasGamification,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.maxLessons),callback:function ($$v) {_vm.$set(_vm.form, "maxLessons", _vm._n($$v))},expression:"form.maxLessons"}})]}}])}),_c('ValidationProvider',{staticClass:"course-form__cell-2",attrs:{"name":"maxExercises","rules":{ required: !_vm.form.hasGamification, min_value: _vm.form.hasGamification ? 0 : _vm.isSpecial ? 1 : 36 },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"maxExercises","label":"Задания","type":"number","placeholder":"Задания","disabled":_vm.form.hasGamification,"invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.maxExercises),callback:function ($$v) {_vm.$set(_vm.form, "maxExercises", _vm._n($$v))},expression:"form.maxExercises"}})]}}])}),_c('div',{staticClass:"course-form__cell-4"},[_c('div',{staticClass:"text-body-1 text-float-left"},[_vm._v("Примерное расписание на весь курс без учета индивидуальных занятий. "),_c('span',{staticClass:"how-it-works",on:{"click":_vm.openExplanationModal}},[_vm._v("Как это работает?")])])])],1)],2),_c('CourseExplanationPlanStudy',{attrs:{"visible":_vm.planStudyModalVisible},on:{"update:visible":function($event){_vm.planStudyModalVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }