







































































































































































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import CardHint from '@/components/_uikit/CardHint.vue'
import CourseExplanationPlanStudy from '@/components/modals/courses/CourseExplanationPlanStudy.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import FilesList from '@/components/FilesList.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { CourseLargeResource, CourseStoreData, CourseType } from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseStoreDataForClient } from '@/store/types/forms'

@Component({
  components: {
    CardHint,
    CourseExplanationPlanStudy,
    DateInput,
    FilesList,
    Select,
    SwitchInput,
    TextAreaInput,
    TextInput,
    UploadInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CourseBaseForm extends Mixins(NotifyMixin) {
  @Prop({ default: null })
  private course!: CourseLargeResource | null

  @Prop({ default: false })
  private readonly!: boolean

  private planStudyModalVisible = false
  private isSwitcherDisable = false

  // Note: Omit<> - вынужденная мера, по скольку на старте дфолтные значения не указаны, но отправлять мы
  // можем только числа на сервер, поэтому мы немного переписываем интерфейс из схемы
  private form: CourseStoreDataForClient = {
    annotation: '',
    educationEndAt: new Date().toISOString().substr(0, 10),
    educationStartAt: new Date().toISOString().substr(0, 10),
    hasGamification: false,
    hiddenAt: new Date().toISOString().substr(0, 10),
    maxExercises: null,
    maxLessons: null,
    salesEndAt: new Date().toISOString().substr(0, 10),
    subjectId: 0,
    teacherIds: [],
    title: '',
    shopLabel: '',
    type: CourseType.DEFAULT,
    openDashboardAt: new Date().toISOString().substr(0, 10),
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get types () {
    return DictionaryModule.courseTypes
  }

  private get isSpecial () {
    return this.course ? this.course.type.value === CourseType.SPECIAL : this.form.type === CourseType.SPECIAL
  }

  private get teachersList() {
    return ManagerCoursesModule.teachersList
  }

  private mounted () {
    if (!this.teachersList.length) {
      this.fetchTeachers()
    } else {
      this.fillFormTeacher()
    }
    if (this.course) {
      this.form = {
        annotation: this.course.annotation,
        educationEndAt: this.course.educationEndAt,
        educationStartAt: this.course.educationStartAt,
        hasGamification: !this.course.hasGamification,
        hiddenAt: this.course.hiddenAt,
        maxExercises: this.course.maxExercises,
        maxLessons: this.course.maxLessons,
        salesEndAt: this.course.salesEndAt,
        subjectId: +this.course.subject.value,
        teacherIds: this.course.teachers.map(teacher => teacher.id) || [],
        title: this.course.title,
        shopLabel: this.course.shopLabel || '',
        type: this.course.type.value as CourseType,
        openDashboardAt: this.course.openDashboardAt,
      }

      this.isSwitcherDisable = this.course.hasGamification
    } else {
      /** NOTE:
       * Почему-то, если сразу задавать значение в true, свитч его не подтягивает, поэтому пришлось переопределять его при маунтинге
       * Флаг, в форме компонента, имеет инвертированное значение:
       *  true - геймификация курса отключена, а свитчер в положении on.
       *  false - геймификация курса ВКЛЮЧЕНА, а свитчер в положении off.
       * Это необходимо для правильного отображения в тогле.
       * Перед отправкой на бэке производится повторное инвертирование
       */
      this.form.hasGamification = true
    }
  }

  private openExplanationModal() {
    this.planStudyModalVisible = true
  }

  private handleDeleteTeacher(id: number) {
    this.form.teacherIds = this.form.teacherIds.filter(item => item !== id)
  }

  @Debounce(500)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const formData = {
            ...this.form,
            // Инвертируем локальный флаг перед отправков на бэк
            hasGamification: !this.form.hasGamification,
          }
          ManagerCoursesModule.saveCourse({
            courseID: this.course ? this.course.id : undefined,
            params: formData as CourseStoreData,
          })
            .then((response: any) => {
              if (this.course) {
                this.notifySuccess('Курс сохранен')
              } else {
                this.notifySuccess('Курс создан')
                requestAnimationFrame(() => (form.reset()))
                this.$router.push({ name: 'manager.control.courses.item', params: { courseID: response.id } })
              }
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private fillFormTeacher() {
    // Предзаполняем преподаватей, если они еще не заполнены
    if (!this.form.teacherIds.length && this.course)
      this.form.teacherIds = this.teachersList.map(teacher => teacher.id)
  }

  private fetchTeachers() {
    ManagerCoursesModule.fetchTeachers()
      .then(() => {
        this.fillFormTeacher()
      })
      .catch(this.notifyError)
  }

  private handleChoiceSubject(id: number) {
    this.form.teacherIds = this.teachersList.filter(teacher => teacher.subject.value === id).map(teacher => teacher.id)
  }
}
